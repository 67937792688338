export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12')
];

export const server_loads = [0,2];

export const dictionary = {
		"/": [~4],
		"/contact": [~5],
		"/document-creator": [~6],
		"/problems": [~7],
		"/problems/[slug]": [~8],
		"/user-guide": [9,[2]],
		"/user-guide/(pages)/sds-by-number": [11,[2,3]],
		"/user-guide/(pages)/sds-by-topic": [12,[2,3]],
		"/user-guide/(pages)/[slug]": [~10,[2,3]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';